<template>
  <div class="orderTest-container">
    <edit ref="iosEdit" :type="2"></edit>
  </div>
</template>
<script>
  import edit from '../androidDev'
  export default {
    name: 'IosDev',
    components: { edit },
    data() {
      return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  }
</script>
<style lang="scss" scoped></style>
